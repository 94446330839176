<template>
  <nav v-if="!$route.meta.hideNavigation" class="rounded-0">
    <v-app-bar fixed color="primary" :elevation="0" style="padding-left:9.5%;padding-right:9.5%">
      <v-overlay :value="loading">
        <v-progress-circular :size="70" :width="7" :opacity="opacity" indeterminate color="white"></v-progress-circular>
      </v-overlay>
      <v-img class="mx-5" align="center" max-height="50" max-width="100" src="../assets/indogrosir.png"></v-img>
      <!-- <v-app-bar-nav-icon @click.stop="drawer = !drawer" :disabled="buttonSidebar"></v-app-bar-nav-icon> -->
      <v-toolbar-title class="text-uppercase white--text">
        <span class="font-weight-light">Bukti</span>
        <span>Potong</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn text color="white" @click="chooseCustomer" :disabled="buttonPilihCustomer">
        <span>{{ this.customer_name }}</span>
      </v-btn>
      <!-- <v-btn v-else text color="grey" @click="chooseCustomer" :disabled="buttonSidebar">          
                  <span>{{this.customer_name}}</span>
              </v-btn> -->
      <v-btn text color="white" @click="logout">
        <span>Sign Out</span>
        <v-icon right>exit_to_app</v-icon>
      </v-btn>
    </v-app-bar>
    <div style="margin-top:65px;padding-left:11%;padding-right:11s%" class="white" outlined fixed v-if="buttonSidebar">
      <v-menu open-on-hover offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="white" v-bind="attrs" v-on="on" :elevation="0" class="font-weight-grey">
            Profile
            <v-icon> mdi-chevron-down </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="item in items" :key="item.title" link @click="showDialog(item.action)">
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-menu open-on-hover offset-y v-for="itemHeader in menuHeader" :key="itemHeader.menu_id">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="white" v-bind="attrs" v-on="on" :elevation="0" @mouseover="newHover(itemHeader.menu_id)">
            {{ itemHeader.menu_name }}
            <v-icon> mdi-chevron-down </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="link in menuLines" :key="link.text" link :to="link.route">
            <v-list-item-content>
              <v-list-item-title>{{ link.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-for="link in menuItem4" :key="link.text" link @click="showDialog(link.action)">
            <v-list-item-content>
              <v-list-item-title>{{ link.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <div style="margin-top:85px;padding-left:14.5%;padding-right:14.5%" class="white" outlined fixed v-else>

    </div>
    <v-overlay :value="overlay">
      <v-progress-circular :size="70" :width="7" :opacity="opacity" indeterminate color="white"></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="dialogPassword" max-width="600px">
      <v-card>
        <v-toolbar color="primary" dark>Edit Data</v-toolbar>
        <v-card-text>
          <v-container>
            <v-alert dense dismissible text type="warning" v-if="validation.new_password">
              {{ validation.new_password[0] }}
            </v-alert>
            <v-alert dense dismissible text type="warning" v-if="validation.confirm_password">
              {{ validation.confirm_password[0] }}
            </v-alert>
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="editedItem.new_password" label="New Password" type=password></v-text-field>
                <v-text-field v-model="editedItem.confirm_password" label="Confirm Password" type=password>
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDialog">
            Cancel
          </v-btn>
          <v-btn color="blue darken-1" text @click="saveData('password')">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogNpwp" max-width="600px">
      <v-card>
        <v-toolbar color="primary" dark>Edit Npwp</v-toolbar>
        <v-card-text>
          <v-container>
            <v-alert dense dismissible text type="warning" v-if="validation.new_password">
              {{ validation.new_password[0] }}
            </v-alert>
            <v-alert dense dismissible text type="warning" v-if="validation.confirm_password">
              {{ validation.confirm_password[0] }}
            </v-alert>
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="new_npwp.npwp_baru" label="New Npwp"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDialog">
            Cancel
          </v-btn>
          <v-btn color="blue darken-1" text @click="saveData('npwp')">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogEmail" max-width="600px">
      <v-card>
        <v-toolbar color="primary" dark>Edit Email</v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="new_email.email_baru" label="New Email"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDialog">
            Cancel
          </v-btn>
          <v-btn color="blue darken-1" text @click="saveData('email')">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <v-dialog v-model="dialogEmail" max-width="600px">
        <v-card>
          <v-toolbar color="success" dark>Edit Email</v-toolbar>
          <v-card-text>
            <v-container>
              <v-alert dense dismissible text type="warning" v-if="validation.new_password">
                {{ validation.new_password[0] }}
              </v-alert>
              <v-alert dense dismissible text type="warning" v-if="validation.confirm_password">
                {{ validation.confirm_password[0] }}
              </v-alert>
              <v-row>
                <v-col cols="12">
                  <v-text-field v-model="new_email.email_baru" label="New Email"></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDialog">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="saveData('email')">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog> -->
    <v-dialog v-model="dialogBank" max-width="600px">
      <v-card>
        <v-toolbar color="primary" dark>Edit Bank Credential</v-toolbar>
        <v-card-text>
          <v-container>
            <v-alert dense dismissible text type="warning" v-if="validation.new_password">
              {{ validation.new_password[0] }}
            </v-alert>
            <v-alert dense dismissible text type="warning" v-if="validation.confirm_password">
              {{ validation.confirm_password[0] }}
            </v-alert>
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="new_bank.nama_bank" label="Nama Bank"></v-text-field>
                <v-text-field v-model="new_bank.no_rek" label="Nomor Rekening"></v-text-field>
                <v-text-field v-model="new_bank.nama_rekening" label="Nama Pemilik Rekening"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDialog">
            Cancel
          </v-btn>
          <v-btn color="blue darken-1" text @click="saveData('bank')">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogReportPenyerahan" max-width="600px">
      <v-card>
        <v-toolbar color="primary" dark>Cetak Report Penyerahan Dokumen Refund</v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                  offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="date" label="Start Date" prepend-icon="mdi-calendar" readonly v-bind="attrs"
                      v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="date" @input="menu2 = false"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-menu v-model="menu3" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                  offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="date_end" label="End Date" prepend-icon="mdi-calendar" readonly
                      v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="date_end" @input="menu3 = false"></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-select v-model="tipePPh" :items="itemPPH" label="Tipe Pph" required></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDialog">
            Cancel
          </v-btn>
          <v-btn color="blue darken-1" text @click="submitReport()">
            Cetak Report
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogReportPenyerahanSubmit" max-width="300px">
      <v-card>
        <v-toolbar color="primary" dark>Pilih Format Report</v-toolbar>
        <v-card-text align-center>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6" align="center" justify="right">
                <v-btn dark color="error" @click="submitCetakReportBupotPdf('pdf')">
                  PDF
                </v-btn>
              </v-col>
              <v-col cols="12" sm="6" md="6" align="center" justify="left">
                <v-btn dark color="success" @click="submitCetakReportBupotPdf('csv')">
                  CSV
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDialogFormatReport()">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogReportPenyerahanRefundSubmit" max-width="300px">
      <v-card>
        <v-toolbar color="primary" dark>Pilih Format Report</v-toolbar>
        <v-card-text align-center>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6" align="center" justify="right">
                <v-btn dark color="error" @click="submitCetakReportRefundPdf('pdf')">
                  PDF
                </v-btn>
              </v-col>
              <v-col cols="12" sm="6" md="6" align="center" justify="left">
                <v-btn dark color="success" @click="submitCetakReportRefundPdf('csv')">
                  CSV
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDialogFormatReport()">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogReportPenyerahanBupot" max-width="600px">
      <v-card>
        <v-toolbar color="primary" dark>Cetak Report Penyerahan Dokumen Bupot</v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-menu v-model="menu4" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                  offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="date_start_bupot" label="Start Date" prepend-icon="mdi-calendar" readonly
                      v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="date_start_bupot" @input="menu4 = false"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-menu v-model="menu5" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                  offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="date_end_bupot" label="End Date" prepend-icon="mdi-calendar" readonly
                      v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="date_end_bupot" @input="menu5 = false"></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-select v-model="tipePPhBupot" :items="itemPPH" label="Tipe Pph" required></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDialog">
            Cancel
          </v-btn>
          <v-btn color="blue darken-1" text @click="submitReportBupot()">
            Cetak Report
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-navigation-drawer v-model="drawer" absolute temporary style="background:#3c4b64;" width="auto">
      <!-- <v-layout column align-center>
            <v-flex class="mt-5">
              <v-avatar size="100">
                <img class="text-lg-center" src="/avatar-1.png">
              </v-avatar>
              <p class="white--text subheading mt-1">The Net Ninja</p>
            </v-flex>
            <v-flex class="mt-4 mb-3">
              <Popup/>
            </v-flex>
          </v-layout> -->
      <v-list>
        <v-img class="mx-auto" align="center" max-height="150" max-width="200" src="../assets/indogrosir.png"></v-img>
        <br>
        <v-divider color="grey"></v-divider>
        <v-list-group>
          <template v-slot:appendIcon>
            <v-icon color="white"> mdi-chevron-down </v-icon>
          </template>
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon class="white--text">mdi-account-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="white--text">User Management</v-list-item-title>
          </template>
          <v-list-item v-for="item in items" :key="item.title" link @click="showDialog(item.action)">
            <v-list-item-icon>
              <v-icon class="white--text">{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title class="white--text">{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-group :value="true">
          <template v-slot:appendIcon>
            <v-icon color="white"> mdi-chevron-down </v-icon>
          </template>
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon class="white--text">mdi-book-plus-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="white--text">User Action</v-list-item-title>
          </template>
          <v-list-item v-for="link in menuItem2" :key="link.text" link :to="link.route">
            <v-list-item-icon>
              <v-icon class="white--text">{{ link.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title class="white--text">{{ link.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-for="link in menuItem4" :key="link.text" link @click="showDialog(link.action)">
            <v-list-item-icon>
              <v-icon class="white--text">{{ link.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title class="white--text">{{ link.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
export default {
  data() {
    return {
      date: '',
      date_end: '',
      date_start_bupot: '',
      date_end_bupot: '',
      buttonSidebar: true,
      buttonPilihCustomer: true,
      data_customer: {},
      npwp: {},
      new_npwp: {},
      new_email: {},
      new_bank: {},
      validation: [],
      editedItem: {},
      dialogEmail: false,
      dialogPassword: false,
      dialogNpwp: false,
      dialogBank: false,
      loading: false,
      opacity: 0.5,
      dialogReportPenyerahan: false,
      dialogReportPenyerahanBupot: false,
      dialogReportPenyerahanSubmit: false,
      dialogReportPenyerahanRefundSubmit: false,
      drawer: false,
      menu2: false,
      menu3: false,
      menu4: false,
      menu5: false,
      menuItem: [],
      overlay: false,
      itemPPH: [
        'PPh 23',
        'PPh Final',
        'All'
      ],
      data: [],
      adminmenuItem: [],
      menuHeader: [],
      menuLines: [],
      menuItem2: [],
      menuItem3: [],
      menuItem4: [],
      tipePPh: '',
      tipePPhBupot: '',
      componentKey: 0,
      customerData: [],
      customer_name: '',
      items: [
        { title: 'Ganti Password', icon: 'mdi-lock', action: "gantiData" },
        { title: 'Ganti Email', icon: 'mdi-email', action: "gantiEmail" },
        // { title: 'Ganti NPWP', icon: 'mdi-card-bulleted', action: "gantiNpwp" },
        // { title: 'Ganti Credential Bank', icon: 'mdi-bank', action: "gantiBank" },
      ],
    }
  },
  methods: {
    logout: function () {
      // this.buttonSidebar=true;
      this.$session.destroy();
      this.$store.dispatch('logout').then(() =>
        this.$router.push("/"))
      this.$toast.open({
        position: 'top-right',
        message: 'Berhasil Logout!',
        type: 'success'
      });
    },
    showDialog(action) {
      if (action === "gantiEmail") {
        this.overlay = true;
        this.new_email.email_baru = null;
        axios({
          method: 'post',
          url: 'getUseremailById',
          data: {
            user_id: this.user.id
          }
        })
          .then(response => {
            //  alert(response.data);
            this.new_email.email_baru = response.data;
            this.overlay = false;
            this.dialogEmail = true;
          })
          .catch(error => {
            console.log(error);
          })
      } else if (action === "gantiData") {
        this.dialogPassword = true;
      } else if (action === "gantiNpwp") {
        this.overlay = true;
        axios({
          method: 'post',
          url: 'getcustomernpwpbyid',
          data: {
            cust_id: this.$session.get('customer_id')
          }
        })
          .then(response => {
            this.new_npwp.npwp_baru = response.data.data;
            this.overlay = false
            this.dialogNpwp = true;
          })
          .catch(error => {
            console.log(error);
          })
      } else if (action === 'gantiBank') {
        this.dialogBank = true;
      } else if (action === "reportPenyerahan") {
        this.date = '';
        this.date_end = '';
        this.tipePPh = '';
        this.dialogReportPenyerahan = true;
      } else if (action === "reportPenyerahanBupot") {
        this.date_start_bupot = '';
        this.date_end_bupot
        this.tipePPhBupot = '';
        this.dialogReportPenyerahanBupot = true;
      }


    },
    closeDialog() {
      this.dialogEmail = false;
      this.dialogPassword = false;
      this.dialogNpwp = false;
      this.dialogBank = false;
      this.dialogReportPenyerahan = false;
    },
    closeDialogFormatReport() {
      this.dialogReportPenyerahanSubmit = false;
      this.dialogReportPenyerahanRefundSubmit = false;
    },
    chooseCustomer() {
      this.$session.remove('customer_id');
      this.customer_name = ' ';
      this.buttonSidebar = false;
      this.$router.push('/pilihcustomer');
    },
    saveData(tipe) {
      if (tipe === 'password') {
        if ((typeof this.editedItem.new_password === 'undefined' || this.editedItem.new_password === "") && (typeof this.editedItem.confirm_password === 'undefined' || this.editedItem.confirm_password === "")) {
          this.$swal.fire({
            title: 'Apakah anda ingin mengupdate data ini?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Update'
          }).then((result) => {
            if (result.isConfirmed) {
              axios({
                method: 'post',
                url: 'gantiData',
                data: {
                  new_password: this.editedItem.new_password,
                  confirm_password: this.editedItem.confirm_password,
                  cust_id: this.$session.get('customer_id'),
                  user_id: this.user.id
                }
              })
                .then(() => {
                  this.$swal.fire(
                    'Sukses!',
                    'Password berhasil diganti!',
                    'success'
                  )
                  this.closeDialog();
                  this.editedItem = [];
                  this.new_email = [];
                  this.new_npwp = [];
                })
                .catch(error => {
                  this.validation = error.response.data.data;
                  this.$swal.fire(
                    'Gagal!',
                    'Password gagal diganti!',
                    'warning'
                  )
                })
            }
          })
        } else {
          if (this.editedItem.new_password === this.editedItem.confirm_password) {
            this.$swal.fire({
              title: 'Apakah anda ingin mengupdate password ini?',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Update'
            }).then((result) => {
              if (result.isConfirmed) {
                axios({
                  method: 'post',
                  url: 'gantiData',
                  data: {
                    new_password: this.editedItem.new_password,
                    confirm_password: this.editedItem.confirm_password,
                    cust_id: this.$session.get('customer_id'),
                    user_id: this.user.id
                  }
                })
                  .then(() => {
                    this.$swal.fire(
                      'Sukses!',
                      'Password berhasil diganti!',
                      'success'
                    )
                    this.closeDialog();
                    this.editedItem = [];
                    this.new_email = [];
                    this.new_npwp = [];
                  })
                  .catch(error => {
                    this.validation = error.response.data.data;
                    this.$swal.fire(
                      'Gagal!',
                      'Password gagal diganti!',
                      'warning'
                    )
                  })
              }
            })
          } else {
            this.$swal.fire(
              'Peringatan!',
              'Password Tidak Sama!',
              'warning'
            )
          }
        }
      } else if (tipe === 'npwp') {
        this.$swal.fire({
          title: 'Apakah anda ingin mengupdate Npwp?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Update'
        }).then((result) => {
          if (result.isConfirmed) {
            axios({
              method: 'post',
              url: 'gantinpwp',
              data: {
                new_npwp: this.new_npwp.npwp_baru,
                cust_id: this.$session.get('customer_id'),
                user_id: this.user.id
              }
            })
              .then(() => {
                this.$swal.fire(
                  'Sukses!',
                  'Npwp berhasil diganti!',
                  'success'
                )
                this.closeDialog();
                this.editedItem = [];
                this.new_email = [];
                this.new_npwp = [];
              })
              .catch(error => {
                this.validation = error.response.data.data;
                this.$swal.fire(
                  'Gagal!',
                  'Npwp gagal diganti!',
                  'warning'
                )
              })
          }
        })
      } else if (tipe === 'email') {
        this.$swal.fire({
          title: 'Apakah anda ingin mengupdate Email?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Update'
        }).then((result) => {
          if (result.isConfirmed) {
            axios({
              method: 'post',
              url: 'updateEmail',
              data: {
                new_email: this.new_email.email_baru,
                // cust_id: this.$session.get('customer_id'),
                user_id: this.user.id
              }
            })
              .then(() => {
                this.$swal.fire(
                  'Sukses!',
                  'Email berhasil diganti!',
                  'success'
                )
                this.closeDialog();
                this.editedItem = [];
                this.new_email = [];
                this.new_npwp = [];
              })
              .catch(error => {
                this.validation = error.response.data.data;
                this.$swal.fire(
                  'Gagal!',
                  'Email gagal diganti!',
                  'warning'
                )
              })
          }
        })
      } else if (tipe === 'bank') {
        this.$swal.fire({
          title: 'Apakah anda ingin mengupdate Data Bank Ini?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Update'
        }).then((result) => {
          if (result.isConfirmed) {
            axios({
              method: 'post',
              url: 'gantiBank',
              data: {
                new_bank: this.new_bank.nama_bank,
                new_norek: this.new_bank.no_rek,
                new_namarek: this.new_bank.nama_rekening,
                cust_id: this.$session.get('customer_id'),
                user_id: this.user.id
              }
            })
              .then(() => {
                this.$swal.fire(
                  'Sukses!',
                  'Credential Bank berhasil diganti!',
                  'success'
                )
                this.closeDialog();
                this.editedItem = [];
                this.new_email = [];
                this.new_npwp = [];
              })
              .catch(error => {
                this.validation = error.response.data.data;
                this.$swal.fire(
                  'Gagal!',
                  'Credential Bank gagal diganti!',
                  'warning'
                )
              })
          }
        })
      }
    },
    submitReport() {
      this.dialogReportPenyerahanRefundSubmit = true;
    },
    // submitReportBupot() {
    //   this.overlay = true;
    //   this.dialogReportPenyerahanBupot = false;
    //   var judulPPH = '';
    //   if (this.tipePPhBupot === 'PPh 23') {
    //     this.tipePPhBupot = 'PPH 23'
    //     judulPPH = 'PPH 23';
    //   } else if (this.tipePPhBupot === 'PPh Final') {
    //     this.tipePPhBupot = 'PPH 4 (2)'
    //     judulPPH = 'PPH 4 (2)';
    //   } else {
    //     this.tipePPhBupot = 'All'
    //     judulPPH = 'PPh 23 dan Final';
    //   }
    //   if (this.date_start_bupot != '' && this.date_end_bupot != '') {
    //     axios({
    //       method: 'post',
    //       url: 'reportPenyerahanBupot',
    //       data: {
    //         start_date: this.date_start_bupot,
    //         end_date: this.date_end_bupot,
    //         tipe_pph: this.tipePPhBupot
    //       }
    //     })
    //       .then((response) => {
    //         var raw = response.data.data;
    //         axios({
    //           method: 'post',
    //           url: 'getDetilBupotPenyerahanBupot',
    //           data: {
    //             start_date: this.date_start_bupot,
    //             end_date: this.date_end_bupot,
    //             tipe_pph: this.tipePPhBupot
    //           }
    //         })
    //           .then((response) => {
    //             // console.log(response);
    //             // var raw = response.data.data;
    //             var body = [];
    //             var nomor = 1;
    //             var sumtotalPPh = 0;
    //             for (var i = 0; i < raw.length; i++) {
    //               var row = [];
    //               var bodyRow = [];
    //               // var nextRow = []
    //               for (var key in raw[i]) {
    //                 row.push(raw[i][key])
    //               }
    //               var nomor_refund = row[1];
    //               var nama_supplier = row[2];
    //               var kode_supplier = row[3];
    //               var totalPPh = row[4];
    //               var tanggal_validasi = row[5];

    //               bodyRow.push({
    //                 content: nomor,
    //                 styles: { valign: 'middle', halign: 'center' },
    //               })

    //               bodyRow.push({
    //                 content: nomor_refund,
    //                 styles: { valign: 'middle', halign: 'center' },
    //               })
    //               bodyRow.push({
    //                 content: nama_supplier,
    //                 styles: { valign: 'middle', halign: 'center' },
    //               })
    //               bodyRow.push({
    //                 content: kode_supplier,
    //                 styles: { valign: 'middle', halign: 'center' },
    //               })
    //               bodyRow.push({
    //                 content: this.formatCurrency2(totalPPh),
    //                 styles: { valign: 'middle', halign: 'right' },
    //               })
    //               bodyRow.push({
    //                 content: tanggal_validasi,
    //                 styles: { valign: 'middle', halign: 'center' },
    //               })
    //               nomor = nomor + 1;
    //               sumtotalPPh = sumtotalPPh + totalPPh;
    //               body.push(bodyRow);
    //             }
    //             const logo = require('@/assets/indogrosir.png')
    //             var imgLogo = new Image()
    //             imgLogo.src = logo
    //             // var img3 = new Image();
    //             // img3.url = '../../../assets/indogrosir.png';
    //             // doc.text(header, 13, 5, { baseline: 'middle' });
    //             var doc = new jsPDF();
    //             // doc.addImage("../../../assets/indogrosir.png", 'JPEG', 55, 25,100,25);
    //             doc.setFont("helvetica", "bold");
    //             doc.addImage(imgLogo, 'PNG', 5, 5, 50, 20)
    //             doc.getFontList();
    //             doc.text("DOKUMEN BUKTI POTONG", 105, 50, { baseline: 'middle', align: 'center', fontStyle: 'bold' });
    //             doc.text(judulPPH, 105, 58, { baseline: 'middle', align: 'center', fontStyle: 'bold' });

    //             doc.setFont("helvetica", "normal");
    //             doc.setFontSize(6);
    //             doc.autoTable({
    //               columnStyles: {
    //                 0: { cellWidth: 10 },
    //                 1: { cellWidth: 40 },
    //                 2: { cellWidth: 50 },
    //                 3: { cellWidth: 30 },
    //                 4: { cellWidth: 30 },
    //                 5: { cellWidth: 40 }
    //               },
    //               headStyles: {
    //                 lineWidth: 0.5,
    //                 lineColor: [0, 0, 0],
    //                 fillColor: [255, 255, 255],
    //                 textColor: 'black'
    //               },
    //               theme: 'grid',
    //               head: [[
    //                 { content: 'No ', styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
    //                 { content: 'Nomor Tanda Terima  Penyerahan Bukti Potong', styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
    //                 { content: 'Nama Supplier ', styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
    //                 { content: 'Kode Supplier ', styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
    //                 { content: 'Total PPh (Rp.)', styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
    //                 { content: 'Tanggal Validasi ', styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
    //               ]],
    //               bodyStyles: { lineWidth: 0.5, lineColor: [0, 0, 0], fillColor: [255, 255, 255] },
    //               startY: 70,
    //               margin: { top: 5, left: 5, right: 2 },
    //               body: body,
    //               footStyles: {
    //                 lineWidth: 0.5,
    //                 lineColor: [0, 0, 0],
    //                 fillColor: [255, 255, 255],
    //                 textColor: 'black'
    //               },
    //               rowPageBreak: 'auto'
    //             })
    //             let finalY = doc.lastAutoTable.finalY;
    //             doc.setFont("helvetica", "bold");
    //             doc.autoTable({
    //               columnStyles: {
    //                 0: { cellWidth: 130 },
    //                 1: { cellWidth: 30 },
    //                 2: { cellWidth: 40 }
    //               },
    //               bodyStyles: { lineWidth: 0.5, lineColor: [0, 0, 0], fillColor: [255, 255, 255] },
    //               body: [[
    //                 { content: 'TOTAL', colSpan: 1, styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
    //                 { content: this.formatCurrency2(sumtotalPPh), colSpan: 1, styles: { halign: 'right', fillColor: [255, 255, 255], textColor: 'black', fontSize: 9 } },
    //                 { content: '', colSpan: 1, styles: { halign: 'right', fillColor: [224, 224, 235], textColor: 'black', fontSize: 9 } }
    //               ]],
    //               margin: { top: 5, left: 5, right: 2 },
    //               startY: finalY,
    //               lineColor: [0, 0, 0]
    //             })
    //             finalY = doc.lastAutoTable.finalY;
    //             // finalY = finalY + 20;
    //             // doc.text("Diserahkan,", 30, finalY, { baseline: 'middle', align: 'center', fontStyle: 'bold' });
    //             // doc.text("Diterima,", 180, finalY, { baseline: 'middle', align: 'center', fontStyle: 'bold' });

    //             // finalY = finalY + 30;
    //             // doc.setFont("helvetica", "normal");
    //             // doc.text("(Taxation HO Igr.),", 30, finalY, { baseline: 'middle', align: 'center', fontStyle: 'bold' });
    //             // doc.text("(Finance Payment HO Igr.),", 180, finalY, { baseline: 'middle', align: 'center', fontStyle: 'bold' });
    //             // doc.addPage();
    //             // var raw_detil = response.data.data;
    //             // var body_detil = [];
    //             // var nomor_detil = 1;

    //             // for (var y = 0; y < raw_detil.length; y++) {
    //             //   var row_detil = [];
    //             //   var bodyRowDetil = [];
    //             //   // var nextRow = []
    //             //   for (var key_detil in raw_detil[y]) {
    //             //     row_detil.push(raw_detil[y][key_detil])
    //             //   }
    //             //   var nomor_refund_detil = row_detil[1];
    //             //   var nomor_bupot = row_detil[2];
    //             //   var tanggal_bupot = row_detil[3];
    //             //   var nomor_kwt = row_detil[4];
    //             //   var nilai_pph = row_detil[5];
    //             //   var nama_supplier_detil = row_detil[6];

    //             //   bodyRowDetil.push({
    //             //     content: nomor_detil,
    //             //     styles: { valign: 'middle', halign: 'center' },
    //             //   })

    //             //   bodyRowDetil.push({
    //             //     content: nomor_refund_detil,
    //             //     styles: { valign: 'middle', halign: 'center' },
    //             //   })
    //             //   bodyRowDetil.push({
    //             //     content: nomor_bupot,
    //             //     styles: { valign: 'middle', halign: 'center' },
    //             //   })
    //             //   bodyRowDetil.push({
    //             //     content: tanggal_bupot,
    //             //     styles: { valign: 'middle', halign: 'center' },
    //             //   })
    //             //   bodyRowDetil.push({
    //             //     content: nomor_kwt,
    //             //     styles: { valign: 'middle', halign: 'center' },
    //             //   })
    //             //   bodyRowDetil.push({
    //             //     content: this.formatCurrency2(nilai_pph),
    //             //     styles: { valign: 'middle', halign: 'right' },
    //             //   })
    //             //   bodyRowDetil.push({
    //             //     content: nama_supplier_detil,
    //             //     styles: { valign: 'middle', halign: 'center' },
    //             //   })
    //             //   nomor_detil = nomor_detil + 1;
    //             //   body_detil.push(bodyRowDetil);
    //             // }
    //             // doc.autoTable({
    //             //   columnStyles: {
    //             //     0: { cellWidth: 10 },
    //             //     1: { cellWidth: 40 },
    //             //     2: { cellWidth: 30 },
    //             //     3: { cellWidth: 30 },
    //             //     4: { cellWidth: 30 },
    //             //     5: { cellWidth: 30 },
    //             //     6: { cellWidth: 30 },
    //             //   },
    //             //   headStyles: {
    //             //     lineWidth: 0.5,
    //             //     lineColor: [0, 0, 0],
    //             //     fillColor: [255, 255, 255],
    //             //     textColor: 'black'
    //             //   },
    //             //   theme: 'grid',
    //             //   head: [[
    //             //     { content: 'No ', styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
    //             //     { content: 'Nomor Tanda Terima  Penyerahan Bukti Potong', styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
    //             //     { content: 'No Bupot ', styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
    //             //     { content: 'Tanggal Bupot ', styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
    //             //     { content: 'Nomor Kwt', styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
    //             //     { content: 'Nilai PPh (Rp.)', styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
    //             //     { content: 'Nama Supplier ', styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
    //             //   ]],
    //             //   bodyStyles: { lineWidth: 0.5, lineColor: [0, 0, 0], fillColor: [255, 255, 255] },
    //             //   startY: 10,
    //             //   margin: { top: 5, left: 5, right: 2 },
    //             //   body: body_detil,
    //             //   footStyles: {
    //             //     lineWidth: 0.5,
    //             //     lineColor: [0, 0, 0],
    //             //     fillColor: [255, 255, 255],
    //             //     textColor: 'black'
    //             //   },
    //             //   rowPageBreak: 'auto'
    //             // })
    //             doc.setProperties({
    //               title: "Laporan Bukti Potong"
    //             });
    //             this.overlay = false;
    //             this.date_end_bupot = '';
    //             this.date_start_bupot = '';
    //             this.tipePPhBupot = '';
    //             window.open(doc.output('bloburl'))
    //           })
    //           .catch(error => {
    //             this.validation = error.response.data.data;
    //           })
    //       })
    //       .catch(error => {
    //         this.validation = error.response.data.data;
    //         this.$swal.fire(
    //           'Gagal!',
    //           'Tidak ada data pada periode yang dipilih!',
    //           'warning'
    //         )
    //       })

    //   } else {
    //     this.overlay = true;
    //     this.$swal.fire(
    //       'Peringatan!',
    //       'Start Date dan End Date Wajib di Isi!',
    //       'warning'
    //     )
    //   }
    // },

    submitReportBupot() {
      this.dialogReportPenyerahanSubmit = true;
    },
    submitCetakReportBupotPdf(tipe) {
      this.overlay = true;
      this.dialogReportPenyerahanBupot = false;
      this.dialogReportPenyerahanSubmit = false;
      var judulPPH = '';
      if (this.tipePPhBupot === 'PPh 23') {
        this.tipePPhBupot = 'PPH 23'
        judulPPH = 'PPH 23';
      } else if (this.tipePPhBupot === 'PPh Final') {
        this.tipePPhBupot = 'PPH 4 (2)'
        judulPPH = 'PPH 4 (2)';
      } else {
        this.tipePPhBupot = 'All'
        judulPPH = 'PPh 23 dan Final';
      }
      if (this.date_start_bupot != '' && this.date_end_bupot != '') {
        if (tipe === 'pdf') {
          axios({
            method: 'post',
            url: 'reportPenyerahanBupot',
            data: {
              start_date: this.date_start_bupot,
              end_date: this.date_end_bupot,
              tipe_pph: this.tipePPhBupot
            }
          })
            .then((response) => {
              var raw = response.data.data;
              // console.log(response);
              // var raw = response.data.data;
              var body = [];
              var nomor = 1;
              var sumtotalPPh = 0;
              for (var i = 0; i < raw.length; i++) {
                var row = [];
                var bodyRow = [];
                // var nextRow = []
                for (var key in raw[i]) {
                  row.push(raw[i][key])
                }
                var nomor_refund = row[1];
                var nama_supplier = row[2];
                var kode_supplier = row[3];
                var totalPPh = row[4];
                var tanggal_validasi = row[5];
                var nomor_bupot = row[6];
                var tanggal_bupot = row[7];
                var no_kwt = row[8];
                var nilai_pph = row[9];
                bodyRow.push({
                  content: nomor,
                  styles: { valign: 'middle', halign: 'center' },
                })

                bodyRow.push({
                  content: nomor_refund,
                  styles: { valign: 'middle', halign: 'center' },
                })
                bodyRow.push({
                  content: nomor_bupot,
                  styles: { valign: 'middle', halign: 'center' },
                })
                bodyRow.push({
                  content: tanggal_bupot,
                  styles: { valign: 'middle', halign: 'center' },
                })
                bodyRow.push({
                  content: no_kwt,
                  styles: { valign: 'middle', halign: 'center' },
                })
                bodyRow.push({
                  content: this.formatCurrency2(nilai_pph),
                  styles: { valign: 'middle', halign: 'right' },
                })
                bodyRow.push({
                  content: nama_supplier,
                  styles: { valign: 'middle', halign: 'center' },
                })
                bodyRow.push({
                  content: kode_supplier,
                  styles: { valign: 'middle', halign: 'center' },
                })
                // bodyRow.push({
                //   content: this.formatCurrency2(totalPPh),
                //   styles: { valign: 'middle', halign: 'right' },
                // })
                bodyRow.push({
                  content: tanggal_validasi,
                  styles: { valign: 'middle', halign: 'center' },
                })
                nomor = nomor + 1;
                sumtotalPPh = sumtotalPPh + totalPPh;
                body.push(bodyRow);
              }
              const logo = require('@/assets/indogrosir.png')
              var imgLogo = new Image()
              imgLogo.src = logo
              // var img3 = new Image();
              // img3.url = '../../../assets/indogrosir.png';
              // doc.text(header, 13, 5, { baseline: 'middle' });
              var doc = new jsPDF();
              // doc.addImage("../../../assets/indogrosir.png", 'JPEG', 55, 25,100,25);
              doc.setFont("helvetica", "bold");
              doc.addImage(imgLogo, 'PNG', 5, 5, 50, 20)
              doc.getFontList();
              doc.text("DOKUMEN BUKTI POTONG", 105, 50, { baseline: 'middle', align: 'center', fontStyle: 'bold' });
              doc.text(judulPPH, 105, 58, { baseline: 'middle', align: 'center', fontStyle: 'bold' });

              doc.setFont("helvetica", "normal");
              doc.setFontSize(6);
              doc.autoTable({
                columnStyles: {
                  0: { cellWidth: 10 },
                  1: { cellWidth: 22 },
                  2: { cellWidth: 23 },
                  3: { cellWidth: 25 },
                  4: { cellWidth: 25 },
                  5: { cellWidth: 20 },
                  6: { cellWidth: 30 },
                  7: { cellWidth: 20 },
                  8: { cellWidth: 25 },
                },
                headStyles: {
                  lineWidth: 0.5,
                  lineColor: [0, 0, 0],
                  fillColor: [255, 255, 255],
                  textColor: 'black'
                },
                theme: 'grid',
                head: [[
                  { content: 'No ', styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
                  { content: 'Nomor Tanda Terima  Penyerahan Bukti Potong', styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
                  { content: 'No Bupot ', styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
                  { content: 'Tanggal Bupot ', styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
                  { content: 'Nomor Kwt', styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
                  { content: 'Nilai Pph', styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
                  { content: 'Nama Supplier', styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
                  { content: 'Kode Supplier', styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
                  { content: 'Tanggal Validasi', styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
                ]],
                bodyStyles: { lineWidth: 0.5, lineColor: [0, 0, 0], fillColor: [255, 255, 255] },
                startY: 70,
                margin: { top: 5, left: 5, right: 2 },
                body: body,
                footStyles: {
                  lineWidth: 0.5,
                  lineColor: [0, 0, 0],
                  fillColor: [255, 255, 255],
                  textColor: 'black'
                },
                rowPageBreak: 'auto'
              })
              let finalY = doc.lastAutoTable.finalY;
              doc.setFont("helvetica", "bold");
              doc.autoTable({
                columnStyles: {
                  0: { cellWidth: 105 },
                  1: { cellWidth: 20 },
                  2: { cellWidth: 75 }
                },
                bodyStyles: { lineWidth: 0.5, lineColor: [0, 0, 0], fillColor: [255, 255, 255] },
                body: [[
                  { content: 'TOTAL', colSpan: 1, styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
                  { content: this.formatCurrency2(sumtotalPPh), colSpan: 1, styles: { halign: 'right', fillColor: [255, 255, 255], textColor: 'black', fontSize: 9 } },
                  { content: '', colSpan: 1, styles: { halign: 'right', fillColor: [224, 224, 235], textColor: 'black', fontSize: 9 } }
                ]],
                margin: { top: 5, left: 5, right: 2 },
                startY: finalY,
                lineColor: [0, 0, 0]
              })
              finalY = doc.lastAutoTable.finalY;
              doc.setProperties({
                title: "Laporan Bukti Potong"
              });
              this.overlay = false;
              this.date_end_bupot = '';
              this.date_start_bupot = '';
              this.tipePPhBupot = '';
              window.open(doc.output('bloburl'))
            })
            .catch(error => {
              this.validation = error.response.data.data;
              this.$swal.fire(
                'Gagal!',
                'Tidak ada data pada periode yang dipilih!',
                'warning'
              )
            })
        } else {
          // alert("TES");
          axios({
            method: 'post',
            url: '/reportPenyerahanCsv',
            data: {
              start_date: this.date_start_bupot,
              end_date: this.date_end_bupot,
              tipe_pph: this.tipePPhBupot
            },
            responseType: "blob"
          })
            .then((result) => {
              const contentDisposition = result.headers['content-disposition'];

              var fileName = contentDisposition.split(";");
              fileName = fileName[1].replace(' filename=', '');
              // console.log(fileName)
              const url = window.URL.createObjectURL(new Blob([result.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', fileName);
              document.body.appendChild(link);
              link.click();
              this.loadingDownload = false;
              this.overlay = false;
              // const downloadData = window.URL.createObjectURL(new Blob([res.data]));
              // const downloadLink = document.createElement("a");
              // // const getType = downloadData.type;
              // // const getTypeSplit = getType.split("/");
              // downloadLink.href = URL.createObjectURL(new Blob([downloadData]));
              // // downloadLink.setAttribute("download", `file.${getTypeSplit[1]}`);
              // downloadLink.setAttribute("download", `Test.pdf`);
              // downloadLink.style.display = "none";
              // document.body.appendChild(downloadLink);
              // downloadLink.click();
              // document.body.removeChild(downloadLink);
            })
        }

      } else {
        this.overlay = false;
        this.dialogReportPenyerahanSubmit = false;
        this.$swal.fire(
          'Peringatan!',
          'Start Date dan End Date Wajib di Isi!',
          'warning'
        )
      }
    },
    submitCetakReportRefundPdf(tipe) {
      this.overlay = true;
      this.dialogReportPenyerahan = false;
      this.dialogReportPenyerahanRefundSubmit = false;
      var judulPPH = '';
      if (this.tipePPh === 'PPh 23') {
        this.tipePPh = 'PPH 23'
        judulPPH = 'PPH 23';
      } else if (this.tipePPh === 'PPh Final') {
        this.tipePPh = 'PPH 4 (2)'
        judulPPH = 'PPH 4 (2)';
      } else {
        this.tipePPh = 'All'
        judulPPH = 'PPh 23 dan Final';
      }
      if (tipe === 'pdf') {
        if (this.date != '' && this.date_end != '') {
          axios({
            method: 'post',
            url: 'reportPenyerahan',
            data: {
              start_date: this.date,
              end_date: this.date_end,
              tipe_pph: this.tipePPh
            }
          })
            .then((response) => {
              var raw = response.data.data;
              axios({
                method: 'post',
                url: 'getDetilBupotPenyerahanRefund',
                data: {
                  start_date: this.date,
                  end_date: this.date_end,
                  tipe_pph: this.tipePPh
                }
              })
                .then((response) => {
                  // console.log(response);
                  // var raw = response.data.data;
                  var body = [];
                  var nomor = 1;
                  var sumtotalPPh = 0;
                  for (var i = 0; i < raw.length; i++) {
                    var row = [];
                    var bodyRow = [];
                    // var nextRow = []
                    for (var key in raw[i]) {
                      row.push(raw[i][key])
                    }
                    var nomor_refund = row[1];
                    var nama_supplier = row[2];
                    var kode_supplier = row[3];
                    var totalPPh = row[4];
                    var tanggal_validasi = row[5];

                    bodyRow.push({
                      content: nomor,
                      styles: { valign: 'middle', halign: 'center' },
                    })

                    bodyRow.push({
                      content: nomor_refund,
                      styles: { valign: 'middle', halign: 'center' },
                    })
                    bodyRow.push({
                      content: nama_supplier,
                      styles: { valign: 'middle', halign: 'center' },
                    })
                    bodyRow.push({
                      content: kode_supplier,
                      styles: { valign: 'middle', halign: 'center' },
                    })
                    bodyRow.push({
                      content: this.formatCurrency2(totalPPh),
                      styles: { valign: 'middle', halign: 'right' },
                    })
                    bodyRow.push({
                      content: tanggal_validasi,
                      styles: { valign: 'middle', halign: 'center' },
                    })
                    nomor = nomor + 1;
                    sumtotalPPh = sumtotalPPh + totalPPh;
                    body.push(bodyRow);
                  }
                  const logo = require('@/assets/indogrosir.png')
                  var imgLogo = new Image()
                  imgLogo.src = logo
                  // var img3 = new Image();
                  // img3.url = '../../../assets/indogrosir.png';
                  // doc.text(header, 13, 5, { baseline: 'middle' });
                  var doc = new jsPDF();
                  // doc.addImage("../../../assets/indogrosir.png", 'JPEG', 55, 25,100,25);
                  doc.setFont("helvetica", "bold");
                  doc.addImage(imgLogo, 'PNG', 5, 5, 50, 20)
                  doc.getFontList();
                  doc.text("TANDA TERIMA PENYERAHAN DOKUMEN REFUND", 105, 50, { baseline: 'middle', align: 'center', fontStyle: 'bold' });
                  doc.text(judulPPH + " KE PIHAK FINANCE", 105, 58, { baseline: 'middle', align: 'center', fontStyle: 'bold' });

                  doc.setFont("helvetica", "normal");
                  doc.setFontSize(6);
                  doc.autoTable({
                    columnStyles: {
                      0: { cellWidth: 10 },
                      1: { cellWidth: 40 },
                      2: { cellWidth: 50 },
                      3: { cellWidth: 30 },
                      4: { cellWidth: 30 },
                      5: { cellWidth: 40 }
                    },
                    headStyles: {
                      lineWidth: 0.5,
                      lineColor: [0, 0, 0],
                      fillColor: [255, 255, 255],
                      textColor: 'black'
                    },
                    theme: 'grid',
                    head: [[
                      { content: 'No ', styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
                      { content: 'Nomor Tanda Terima  Refund Bukti Potong', styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
                      { content: 'Nama Supplier ', styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
                      { content: 'Kode Supplier ', styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
                      { content: 'Total PPh (Rp.)', styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
                      { content: 'Tanggal Validasi ', styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
                    ]],
                    bodyStyles: { lineWidth: 0.5, lineColor: [0, 0, 0], fillColor: [255, 255, 255] },
                    startY: 70,
                    margin: { top: 5, left: 5, right: 2 },
                    body: body,
                    footStyles: {
                      lineWidth: 0.5,
                      lineColor: [0, 0, 0],
                      fillColor: [255, 255, 255],
                      textColor: 'black'
                    },
                    rowPageBreak: 'auto'
                  })
                  let finalY = doc.lastAutoTable.finalY;
                  doc.setFont("helvetica", "bold");
                  doc.autoTable({
                    columnStyles: {
                      0: { cellWidth: 130 },
                      1: { cellWidth: 30 },
                      2: { cellWidth: 40 }
                    },
                    bodyStyles: { lineWidth: 0.5, lineColor: [0, 0, 0], fillColor: [255, 255, 255] },
                    body: [[
                      { content: 'TOTAL', colSpan: 1, styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
                      { content: this.formatCurrency2(sumtotalPPh), colSpan: 1, styles: { halign: 'right', fillColor: [255, 255, 255], textColor: 'black', fontSize: 9 } },
                      { content: '', colSpan: 1, styles: { halign: 'right', fillColor: [224, 224, 235], textColor: 'black', fontSize: 9 } }
                    ]],
                    margin: { top: 5, left: 5, right: 2 },
                    startY: finalY,
                    lineColor: [0, 0, 0]
                  })
                  finalY = doc.lastAutoTable.finalY;
                  finalY = finalY + 20;
                  doc.text("Diserahkan,", 30, finalY, { baseline: 'middle', align: 'center', fontStyle: 'bold' });
                  doc.text("Diterima,", 180, finalY, { baseline: 'middle', align: 'center', fontStyle: 'bold' });

                  finalY = finalY + 30;
                  doc.setFont("helvetica", "normal");
                  doc.text("(Taxation HO Igr.),", 30, finalY, { baseline: 'middle', align: 'center', fontStyle: 'bold' });
                  doc.text("(Finance Payment HO Igr.),", 180, finalY, { baseline: 'middle', align: 'center', fontStyle: 'bold' });
                  doc.addPage();
                  var raw_detil = response.data.data;
                  var body_detil = [];
                  var nomor_detil = 1;

                  for (var y = 0; y < raw_detil.length; y++) {
                    var row_detil = [];
                    var bodyRowDetil = [];
                    // var nextRow = []
                    for (var key_detil in raw_detil[y]) {
                      row_detil.push(raw_detil[y][key_detil])
                    }
                    var nomor_refund_detil = row_detil[1];
                    var nomor_bupot = row_detil[2];
                    var tanggal_bupot = row_detil[3];
                    var nomor_kwt = row_detil[4];
                    var nilai_pph = row_detil[5];
                    var nama_supplier_detil = row_detil[6];

                    bodyRowDetil.push({
                      content: nomor_detil,
                      styles: { valign: 'middle', halign: 'center' },
                    })

                    bodyRowDetil.push({
                      content: nomor_refund_detil,
                      styles: { valign: 'middle', halign: 'center' },
                    })
                    bodyRowDetil.push({
                      content: nomor_bupot,
                      styles: { valign: 'middle', halign: 'center' },
                    })
                    bodyRowDetil.push({
                      content: tanggal_bupot,
                      styles: { valign: 'middle', halign: 'center' },
                    })
                    bodyRowDetil.push({
                      content: nomor_kwt,
                      styles: { valign: 'middle', halign: 'center' },
                    })
                    bodyRowDetil.push({
                      content: this.formatCurrency2(nilai_pph),
                      styles: { valign: 'middle', halign: 'right' },
                    })
                    bodyRowDetil.push({
                      content: nama_supplier_detil,
                      styles: { valign: 'middle', halign: 'center' },
                    })
                    nomor_detil = nomor_detil + 1;
                    body_detil.push(bodyRowDetil);
                  }
                  doc.autoTable({
                    columnStyles: {
                      0: { cellWidth: 10 },
                      1: { cellWidth: 40 },
                      2: { cellWidth: 30 },
                      3: { cellWidth: 30 },
                      4: { cellWidth: 30 },
                      5: { cellWidth: 30 },
                      6: { cellWidth: 30 },
                    },
                    headStyles: {
                      lineWidth: 0.5,
                      lineColor: [0, 0, 0],
                      fillColor: [255, 255, 255],
                      textColor: 'black'
                    },
                    theme: 'grid',
                    head: [[
                      { content: 'No ', styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
                      { content: 'Nomor Tanda Terima  Refund Bukti Potong', styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
                      { content: 'No Bupot ', styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
                      { content: 'Tanggal Bupot ', styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
                      { content: 'Nomor Kwt', styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
                      { content: 'Nilai PPh (Rp.)', styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
                      { content: 'Nama Supplier ', styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
                    ]],
                    bodyStyles: { lineWidth: 0.5, lineColor: [0, 0, 0], fillColor: [255, 255, 255] },
                    startY: 10,
                    margin: { top: 5, left: 5, right: 2 },
                    body: body_detil,
                    footStyles: {
                      lineWidth: 0.5,
                      lineColor: [0, 0, 0],
                      fillColor: [255, 255, 255],
                      textColor: 'black'
                    },
                    rowPageBreak: 'auto'
                  })
                  doc.setProperties({
                    title: "Laporan Bukti Potong"
                  });
                  this.overlay = false;
                  this.date_end = '';
                  this.date = '';
                  this.tipePPh = '';
                  window.open(doc.output('bloburl'))
                })
                .catch(error => {
                  this.validation = error.response.data.data;
                })
            })
            .catch(error => {
              this.validation = error.response.data.data;
              this.$swal.fire(
                'Gagal!',
                'Tidak ada data pada periode yang dipilih!',
                'warning'
              )
            })

        } else {
          this.overlay = false;
          this.dialogReportPenyerahanSubmit = false;
          this.$swal.fire(
            'Peringatan!',
            'Start Date dan End Date Wajib di Isi!',
            'warning'
          )
        }
      } else {
        // alert("TES");
        axios({
          method: 'post',
          url: '/reportPenyerahanRefundCsv',
          data: {
            start_date: this.date,
            end_date: this.date_end,
            tipe_pph: this.tipePPh
          },
          responseType: "blob"
        })
          .then((result) => {
            const contentDisposition = result.headers['content-disposition'];

            var fileName = contentDisposition.split(";");
            fileName = fileName[1].replace(' filename=', '');
            // console.log(fileName)
            const url = window.URL.createObjectURL(new Blob([result.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            this.loadingDownload = false;
            this.overlay = false;
            // const downloadData = window.URL.createObjectURL(new Blob([res.data]));
            // const downloadLink = document.createElement("a");
            // // const getType = downloadData.type;
            // // const getTypeSplit = getType.split("/");
            // downloadLink.href = URL.createObjectURL(new Blob([downloadData]));
            // // downloadLink.setAttribute("download", `file.${getTypeSplit[1]}`);
            // downloadLink.setAttribute("download", `Test.pdf`);
            // downloadLink.style.display = "none";
            // document.body.appendChild(downloadLink);
            // downloadLink.click();
            // document.body.removeChild(downloadLink);
          })
      }
    },
    formatCurrency2(value) {
      var formatter = new Intl.NumberFormat('en-US', {
      });

      return formatter.format(value); /* $2,500.00 */
    },
    renderMenu() {
      this.menuItem = [];
      this.menuItem.splice(0);
      this.menuItem2 = [];
      this.menuItem2.splice(0);
      this.menuItem3 = [];
      this.menuItem3.splice(0);
      this.menuItem4 = [];
      this.menuItem4.splice(0);
      this.menuHeader = [];
      this.menuHeader.splice(0);
      this.customerData = [];
      this.customer_name = '';
      this.$nextTick(() => {
        axios({
          method: 'post',
          url: 'getMenuHeader',
          data: {
            role_id: this.user.role_id
          },
        })
          .then(response => {
            this.menuItem = response.data.data;
            this.data = response.data.data;
            this.data.forEach(item => {
              this.menuHeader.push({
                menu_id: item.menu_id,
                menu_name: item.menu_name,
                role_id: item.role_id
              })
            })
            let uri = `v1/getmenu/${this.user.role_id}`;
            axios.get(uri).then(response => {
              this.menuItem = response.data.data;
              this.data = this.menuItem;
              this.data.forEach(item => {
                this.menuItem2.push({
                  icon: 'books',
                  text: item.menu_detail_name,
                  route: item.menu_detail_desc,
                  menu_id: item.menu_id
                })
              })
            });
            let uri2 = `v1/getmenuReport/${this.user.role_id}`;
            axios.get(uri2).then(response => {
              this.menuItem3 = response.data.data;
              this.data = this.menuItem3;
              this.data.forEach(item => {
                this.menuItem4.push({
                  icon: 'books',
                  text: item.menu_detail_name,
                  action: item.action
                })
              })
            });
          })
          .catch(error => {
            console.log(error.response)
          })
      })
    },
    newHover(item) {
      this.menuLines = this.menuItem2.filter(function (el) {
        return el.menu_id === item;
      });
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
    }),
  },
  mounted() {
    //Trigger pada saat user login
    this.$root.$on('eventing', data => {
      this.menuItem = [];
      this.menuItem.splice(0);
      this.menuItem2 = [];
      this.menuItem2.splice(0);
      this.menuItem3 = [];
      this.menuItem3.splice(0);
      this.menuItem4 = [];
      this.menuItem4.splice(0);
      this.menuHeader = [];
      this.menuHeader.splice(0);
      this.customerData = [];
      this.customer_name = '';
      this.renderMenu();
      console.log(data);
    });
    // Trigger Waktu Setelah Pilih Customer
    this.$root.$on('eventing2', data => {
      if ((typeof this.$session.get('customer_id') === 'undefined' || this.$session.get('customer_id') === "") && this.user.role_id !== 1 && this.user.role_id !== 2) {
        // Jika Pada saat Pemilihan Customer di Refresh
        this.buttonSidebar = false
        this.buttonPilihCustomer = true
      } else if ((typeof this.$session.get('customer_id') === 'undefined' || this.$session.get('customer_id') === "") && this.user.role_id === 1) {
        // Jika Pada saat user login langsung sebagai admin
        this.buttonSidebar = false
        this.buttonPilihCustomer = true
      } else if ((typeof this.$session.get('customer_id') === 'undefined' || this.$session.get('customer_id') === "") && this.user.role_id === 2) {
        // Jika Pada saat user login langsung sebagai validator               
        this.buttonSidebar = false
        this.buttonPilihCustomer = true
      }
      else {
        // Jika saat Supplier masuk ke menu Utama
        this.buttonSidebar = true;
        this.buttonPilihCustomer = false
      }
      axios({
        method: 'post',
        url: 'getcustomerbyid',
        data: {
          cust_id: this.$session.get('customer_id')
        },
      })
        .then(response => {
          this.customerData = response.data.data;
          this.customer_name = this.customerData[0].customer_name;
        })
        .catch(error => {
          console.log(error.response)
        })
      console.log(data);
    });
    this.$root.$on('triggerMenu', data => {
      this.buttonSidebar = false
      console.log(data);
    });
    this.$root.$on('triggerMenuAdmin', data => {
      this.buttonSidebar = true
      console.log(data)
    })
    // Kenapa Isinya sama dengan yang atas? karena jagaan apabila user merefresh halaman pada saat sudah login
    this.renderMenu();
    if ((typeof this.$session.get('customer_id') === 'undefined' || this.$session.get('customer_id') === "") && this.user.role_id !== 1 && this.user.role_id !== 2) {
      this.buttonSidebar = false
      this.buttonPilihCustomer = true
    } else if ((typeof this.$session.get('customer_id') === 'undefined' || this.$session.get('customer_id') === "") && this.user.role_id === 1) {
      this.buttonSidebar = true
      this.buttonPilihCustomer = true
    } else if ((typeof this.$session.get('customer_id') === 'undefined' || this.$session.get('customer_id') === "") && this.user.role_id === 2) {
      this.buttonSidebar = true
      this.buttonPilihCustomer = true
    }
    else {
      this.buttonSidebar = true;
      this.buttonPilihCustomer = false
    }
    axios({
      method: 'post',
      url: 'getcustomerbyid',
      data: {
        cust_id: this.$session.get('customer_id')
      },
    })
      .then(response => {
        this.customerData = response.data.data;
        this.customer_name = this.customerData[0].customer_name;
      })
      .catch(error => {
        console.log(error.response)
      })
    // axios({
    //   method: 'post',
    //   url: 'getcustomerbyid',
    //   data: {
    //     cust_id: this.$session.get('customer_id')
    //   },
    // })
    //   .then(response => {
    //     this.customerData = response.data.data;
    //     this.customer_name = this.customerData[0].customer_name;
    //   })
    //   .catch(error => {
    //     console.log(error.response)
    //   }) 
  },
  created() {
    // alert(this.user.role_id);
    // alert(this.$session.get('customer_id'));
  }
}
</script>