import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index.js'
// import helloworld from '../components/HelloWorld.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    meta: { hideNavigation: true },
    component: () => import(/* webpackChunkName: "about" */ '../components/Login.vue')
  },
  {
    path: '/user',
    name: 'User',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Master/User/User.vue'),
    meta:{
      auth: true,
      admin:true
    }
  },
  {
    path: '/role',
    name: 'Role',
    component: () => import('../views/Master/Role/Role.vue'),
    meta: { 
      auth: true,
      admin:true 
    }
  },
  {
    path: '/customers',
    name: 'Customers',
    component: () => import( '../views/Master/Customers/Customers.vue'),
    meta: { 
      auth: true,
      admin:true 
    }
  },
  {
    path: '/menu',
    name: 'Menu',
    component: () => import( '../views/Master/Menu/Menu.vue'),
    meta: { 
      auth: true,
      admin:true 
    }
  },
  {
    path: '/menudetail',
    name: 'MenuDetail',
    component: () => import( '../views/Master/MenuDetail/MenuDetail.vue'),
    meta: { 
      auth: true,
      admin:true 
    }
  },
  {
    path: '/inputTrx',
    name: 'InputTrx',
    component: () => import( '../views/Master/InputTrx/InputTrx.vue'),
    meta: { 
      auth: true 
    }
  },
  {
    path: '/trxPage',
    name: 'TrxPage',
    component: () => import( '../views/Master/TrxPage/TrxPage.vue'),
    meta: { 
      auth: true,
      redirectValidator: true,
      supplier:true
    }
  },
  {
    path: '/trxPageSubmit',
    name: 'TrxPageSubmit',
    component: () => import( '../views/Master/TrxPage/TrxPageSubmit.vue'),
    meta: { 
      auth: true,
      supplier:true
    }
  },
  {
    path: '/trxPageCancel',
    name: 'TrxPageCancel',
    component: () => import( '../views/Master/TrxPage/TrxPageCancel.vue'),
    meta: { 
      auth: true,
      supplier:true
    }
  },
  {
    path: '/detailbupot/:id',
    name: 'DetailBupot',
    component: () => import( '../views/Master/TrxPage/DetailBupot.vue'),
    meta: { 
      auth: true,
      supplier:true
    }
  },
  {
    path: '/inputinquiryrefund',
    name: 'InputInquiryRefund',
    component: () => import( '../views/Master/TrxPage/InputInquiryRefund.vue'),
    meta: { 
      auth: true,
      supplier:true
    }
  },
  {
    path: '/inputinquirybupot',
    name: 'InputInquiryBupot',
    component: () => import( '../views/Master/TrxPage/InputInquiryBuktiPotong.vue'),
    meta: { 
      auth: true,
      supplier:true
    }
  },
  {
    path: '/masteralasan',
    name: 'MasterAlasan',
    component: () => import( '../views/Master/MasterAlasan/Index.vue'),
    meta: { 
      auth: true,
      admin:true
    }
  },
  {
    path: '/masterpengumuman',
    name: 'MasterPengumuman',
    component: () => import( '../views/Master/MasterPengumuman/MasterPengumuman.vue'),
    meta: { 
      auth: true,
      admin:true
    }
  },
  {
    path: '/trxpagekasir',
    name: 'TrxPageKasir',
    component: () => import( '../views/Master/TrxPageKasir/TrxPageKasir.vue'),
    meta: { 
      auth: true,
      admin:true,
    }
  },
  {
    path: '/trxpagevalidator',
    name: 'TrxPageValidator',
    component: () => import( '../views/Master/TrxPageValidasi/TrxPageValidasi.vue'),
    meta: { 
      auth: true,
      auth_validator: true 
    }
  },
  {
    path: '/trxpageinquirydatavalidator',
    name: 'TrxPageValidatorInquiry',
    component: () => import( '../views/Master/TrxPageValidasi/TrxPageInquiryValidator.vue'),
    meta: { 
      auth: true,
      auth_validator: true 
    }
  },
  {
    path: '/menureportoutstanding',
    name: 'ReportOutstanding',
    component: () => import('../views/Master/ReportOutstanding/Index.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/menureportoutstandinguser',
    name: 'ReportOutstandingUser',
    component: () => import('../views/Master/ReportOutstandingUser/Index.vue'),
    meta: {
      auth: true,
      supplier:true
    }
  },
  {
    path: '/editinquirybupot',
    name: 'EditBuktiPotong',
    component: () => import( '../views/Master/TrxPage/EditBuktiPotong.vue'),
    meta: { 
      auth: true,
      supplier:true
    }
  },
  {
    path: '/detailbupotvalidator/:id',
    name: 'DetailBupotValidator',
    component: () => import( '../views/Master/TrxPageValidasi/DetailBupotValidator.vue'),
    meta: { 
      auth: true,
      auth_validator: true 
    }
  },
  {
    path:'/foradmin',
    name: 'ForAdmin',
    component: () => import( '../components/ForAdmin.vue'),
    meta: {
      auth: true,
      hideNavigation: true
    }
  },
  {
    path:'/forvalidator',
    name: 'ForValidator',
    component: () => import( '../components/ForValidator.vue'),
    meta: {
      auth: true,
      hideNavigation: true,
    }
  },
  {
    path:'/forkasir',
    name: 'ForKasir',
    component: () => import( '../components/ForKasir.vue'),
    meta: {
      auth: true,
      hideNavigation: true 
    }
  },
  {
    path:'/pdf',
    name: 'Pdf',
    component: () => import( '../views/Master/Pdf/Pdf.vue')
  },
  {
    path:'/forkasir2',
    name: 'ForKasir2',
    component: () => import( '../components/ForKasir2.vue'),
    meta: {
      auth: true,
      hideNavigation: true,
    }
  },
  {
    path:'/pilihcustomer',
    name: 'PilihCustomer',
    component: () => import( '../views/Master/MasterChooseCustomer/Index.vue'),
  },
  {
    path:'/formharilibur',
    name: 'HariLibur',
    component: () => import( '../views/Master/HariLibur/Index.vue'),
  },
  {
    path:'/mastertarifkwt',
    name: 'MasterTarifKwt',
    component: () => import( '../views/Master/MasterTarifKwt/MasterTarifKwt.vue'),
  },
  {
    path: '*',
    meta: { hideNavigation: true },
    component: () => import( '../components/NotFound.vue')
  },
  {
    path: '/forsupplier',
    meta: { hideNavigation: true },
    component: () => import( '../components/ForSupplier.vue')
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => { 
  if (to.matched.some(record => record.meta.auth)) {
    if (store.getters.isLoggedIn && store.getters.user) {
      next()
      if (to.matched.some(record => record.meta.kasir)){
        if(store.getters.user.role_id == 1){
          next()
        }else{
          next('/forkasir')
        }
      }
      if (to.matched.some(record => record.meta.auth_validator)){
        if(store.getters.user.role_id == 2){
          next()
        }else{
          next('/forvalidator')
        }
      }
      if (to.matched.some(record => record.meta.admin)){
        if(store.getters.user.role_id == 1){
          next()
        }else{
          next('/foradmin')
        }
      }
      if (to.matched.some(record => record.meta.supplier)){
        if(store.getters.user.role_id == 3){
          next()
        }else{
          next('/forsupplier')
        }
      }
      return
    } else {
      next('/')
    }
  }
  next()
})

export default router
