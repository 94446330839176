import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
        isLoggedIn: false, 
    customer: null
  },
  mutations: {
        set_user (state, data) {
        state.user = data
        state.isLoggedIn = true
        }, 
        reset_user (state) {
        state.user = null
        state.isLoggedIn = false
        },
        updateCustomer (state, newCustomer) { 
          state.customer = newCustomer
        },
        reset_customer (state) {
          state.customer = null
        }
  }, 
  getters: {
        isLoggedIn (state){
        return state.isLoggedIn
        },
        user (state) {
        return state.user
        },
        customer (state) {
        return state.customer
        }
    
  },
  actions: {
    login({  commit }, data) {
    return new Promise((resolve, reject) => { 
      axios.post('login', data)
        .then(response => {
          // alert(JSON.stringify(response q q));
         const token = response.data.token  
         localStorage.setItem('token', token) 
         axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
          // dispatch('get_user')
          // console.log(this.user);
         resolve(response) 
       })
       .catch(err => {
         commit('reset_user')  
         localStorage.removeItem('token')
         reject(err)
      })
    })
  },
  async get_user({commit}){ 
    if(!localStorage.getItem('token')){
      return
    }
    try{ 
      let response = await axios.get('user')
      //  alert(JSON.stringify(response))
        commit('set_user', response.data.data)
    } catch (error){
        commit('reset_user') 
        delete axios.defaults.headers.common['Authorization']
        localStorage.removeItem('token')
        return error
    } 
    },
    logout({ commit }) {
      return new Promise((resolve) => {
      commit('reset_user')
      localStorage.removeItem('token')
      delete axios.defaults.headers.common['Authorization']
      resolve()
      })
    },
    register({ commit }, data) {
      return new Promise((resolve, reject) => { 
      axios.post('register', data)
      .then(resp => { 
       resolve(resp)
      })
     .catch(err => {
      commit('reset_user')   
      reject(err)
    })
    })
  },
  async set_customer({commit}, data){
    commit('updateCustomer',data)
  }
  }
})
