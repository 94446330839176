<template>
  <v-app class="grey lighten-3">
    <v-main class="grey lighten-3">
      <Navbar />
      <router-view></router-view>
    </v-main>
  </v-app>
</template>
 
<script>
import Navbar from '@/components/Navbar'
export default {
  name: 'App',

  components: {
    Navbar
  },

  data: () => ({
    //
  }),
};
</script>
